import React from "react";
import { Link } from "gatsby";

const CTA = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div
              className="text-center"
              data-aos="fade-up"
              data-aos-delay={500}
            >
              <Link className="text-white font-size-6 mb-0" to="/contact">
                <span className="text-red mr-3">
                  <i className="fa fa-heart" />
                </span>
                Contacta con nosotros y prueba Timeview durante 15 días sin
                compromiso
                <span className="text-red ml-3">
                  <i className="fa fa-heart" />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTA;
